import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Routes from './Routes';
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import AOS from 'aos';
import MobileRoutes from './MobileRoutes';

Modal.setAppElement('#root');
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    toast.configure({ autoClose: 3000 });
    AOS.init();

    return () => { };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
