import React from 'react'

export default function One() {
    return (
        <div> <div class="relative pb-20 pt-[64px] sm:pb-0 lg:pt-[80px]"
            style={{
                paddingBottom: "80px"
            }}
        >
            <img alt="Diamond Lattice" src="https://www.futurepedia.io/diamond-lattice.webp" class="pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover text-transparent" />
            <div class="absolute inset-0 z-[-1] h-full w-full bg_radient_270deg animate-Gradient"></div>
            <div class="mx-auto mt-[40px] w-full max-w-7xl px-4">
                <div class="flex flex-1 flex-col items-center gap-[41px] pb-0 sm:pb-[100px]">
                    <div class="flex w-full flex-col items-center gap-[20px]">
                        <h1 class="text-center text-5xl font-bold tracking-[-0.03em] text-slate-800 md:text-7xl ">Boost Productivity. Embrace AI.</h1>
                        <div class="text-center text-xl font-normal text-slate-600">
                            <div class="rich-text-block mx-auto w-full max-w-7xl">
                                <div class="prose:text-slate-100 prose-xl prose-slate prose-invert prose-headings:scroll-m-24 prose-headings:font-semibold prose-a:underline  prose-a:decoration-inherit prose-ol:list-decimal prose-ul:list-disc">
                                    <p class="my-2">Discover how <strong>10M+ professionals</strong> and businesses are leveraging AI to enhance revenue, efficiency, and savings.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><button class="inline-flex items-center justify-center ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-ice-500 text-primary-foreground hover:bg-ice-600 cursor-pointer rounded-full w-full whitespace-nowrap py-[20px] px-[49.5px] text-xl/[25.2px] font-semibold h-auto" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:R1crq5enla:" data-state="closed">Join for free</button></div>
                </div>
            </div>
        </div>
        </div>
    )
}
