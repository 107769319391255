import React from 'react'

export default function Navbar() {
    return (
        <div><div class="px-4 py-2"
            style={{
                position: "fixed",
                width: "100%",
                backgroundImage: `url("https://www.futurepedia.io/diamond-lattice.webp")`,
                backgroundSize: "cover",
                background: "#f4d3d4",
                zIndex: "9999"
            }}
        >
            <nav class="hidden w-full lg:block">
                <div class="mx-auto flex max-w-7xl items-center justify-between">
                    <a class="pointer" ><img src='' /><span class="sr-only">Home</span></a>
                    <div class="gtmNavbar flex justify-center gap-8">
                        <a class="relative cursor-pointer py-4 text-ice-950 hover:text-ice-950/60" >AI Tools</a><a class="relative cursor-pointer py-4 text-ice-950 hover:text-ice-950/60" href="/ai-tools/ai-agents">AI Agents</a><a class="relative cursor-pointer py-4 text-ice-950 hover:text-ice-950/60" href="/tutorials">AI Tutorials</a><a class="relative cursor-pointer py-4 text-ice-950 hover:text-ice-950/60" href="/ai-innovations">AI Innovations<span class="absolute -right-4 top-2 text-xs text-ice-500">NEW</span></a>
                        <div class="group relative py-4">
                            <a class="cursor-pointer text-ice-950 hover:text-ice-950/60" >Advertise</a>
                            <div class="absolute left-0 top-8 z-50 mt-2 hidden w-48 flex-col overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 group-hover:flex"><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="https://www.passionfroot.me/futurepedia">Sponsorship Options</a><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/submit-tool">Submit A Tool</a><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/youtube-interest">YouTube Channel</a></div>
                        </div>
                        <div class="group relative py-4">
                            <a class="cursor-pointer text-ice-950 hover:text-ice-950/60" >Resources</a>
                            <div class="absolute left-0 top-8 z-50 mt-2 hidden w-48 flex-col overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 group-hover:flex"><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/newsletter">Our Newsletter</a><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/videos">Our Videos</a><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/ai-conferences-list">AI Conferences</a><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/resources/ai-fundamentals">AI Fundamentals</a><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/resources/ai-glossary">AI Glossary</a><a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="/resources/automation">Automations 101</a></div>
                        </div>
                    </div>
                    <div class="flex items-center justify-end gap-4">

                        <div class="flex justify-end">
                            <form class="relative flex items-center gap-2 overflow-hidden p-1 transition-all w-0 px-0" action="/search" method="GET">
                                <input type="text" class="w-full rounded-full border-none bg-gray-100 p-2 pr-20 text-black transition-all md:pr-8" placeholder="Search" name="search" value="" />
                                <button type="submit" value="Search" class="absolute right-2 text-ice-500 hover:text-ice-800 md:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right ">
                                        <path d="M5 12h14"></path>
                                        <path d="m12 5 7 7-7 7"></path>
                                    </svg>
                                </button>
                                <button class="absolute right-8 mr-2 border-r border-r-slate-300 pr-2 text-slate-400 hover:text-slate-500 md:right-2 md:mr-0 md:border-none md:pr-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x h-6 w-6">
                                        <path d="M18 6 6 18"></path>
                                        <path d="m6 6 12 12"></path>
                                    </svg>
                                </button>
                            </form>
                            <button class="flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search cursor-pointer hover:text-ice-500" aria-label="Search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <path d="m21 21-4.3-4.3"></path>
                                </svg>
                            </button>
                        </div>

                        <div class="flex items-center justify-center gap-3"><button class="text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-ice-100 rounded-full h-10 px-4 py-2 flex w-full items-center justify-center gap-2">Login</button><button class="inline-flex items-center justify-center text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-ice-500 text-primary-foreground hover:bg-ice-600 cursor-pointer rounded-full h-10 px-4 py-2 w-full whitespace-nowrap" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:R3mp5enla:" data-state="closed">Sign up for free</button></div>

                    </div>
                </div>
            </nav>



        </div></div>
    )
}
