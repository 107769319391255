import React from 'react'
import '../main.scss'
import One from './One'
import Two from './Two'
import Third from './Three'
import Four from './Four'
import Five from './Five'
import Footer from './Footer'
import Navbar from './Navbar'
export default function index() {
    return (
        <div>
            <Navbar />
            <One />
            <Two />
            <Third />
            <Four />
            <Five />
            <Footer />
        </div>
    )
}
