import React from 'react'
import './landind.scss'
import Sectionne from './SectionOne/index'
import SectionTwo from './SectionTwo'
import ThridSection from './SectionThree/Sectionthree'
import Fourth from './Sectionfour/index'
import Five from './SectionFive/index'
import Six from './SectionSix'
import Seven from './SevenSeven/index'
import Eight from './EightSection/index'
import Nine from './Nine/MineSection'
import Eleven from './Eleven/Eleven'
import Ten from './Ten/Ten'
import Thriteen from './ThirteenSection'
import NavMenu from './Topnavbar'
import Footer from './Footer'
export default function index() {
    return (
        <>
            <div
            className='mainthibg'
             
            >
                <NavMenu />

                {/**/}
                <Sectionne />
                {/* <SectionTwo /> */}
                <ThridSection />
                <Fourth />
                <Five />
                {/* <Six />
                <Seven />
                <Eight />
                <Nine />
                <Ten />
                <Eleven />
                <Thriteen />
                <Footer /> */}
            </div>
        </>
    )
}
