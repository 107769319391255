import React from 'react'

export default function Five() {
    return (
        <div>

            <section class="mx-auto w-full max-w-7xl">
                <section class="mx-auto bg-white px-4 py-10">
                    <div class="flex flex-col items-center gap-4">
                        <h2 class="text-4xl font-bold lg:text-5xl">AI Tool Categories</h2>
                        <p class="text-center text-xl font-normal text-slate-600">

                        </p>
                    </div>
                    <div class="mx-auto mt-10 grid max-w-7xl grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >AI Image Tools</a></h4>
                                <span class="text-sm font-normal text-[#757575]">548 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >AI Business Tools</a></h4>
                                <span class="text-sm font-normal text-[#757575]">2136 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >Automation Tools</a></h4>
                                <span class="text-sm font-normal text-[#757575]">447 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >AI Productivity Tools</a></h4>
                                <span class="text-sm font-normal text-[#757575]">829 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a>AI Text Generators</a></h4>
                                <span class="text-sm font-normal text-[#757575]">627 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >AI Video Tools</a></h4>
                                <span class="text-sm font-normal text-[#757575]">211 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a>AI Code Tools</a></h4>
                                <span class="text-sm font-normal text-[#757575]">368 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >AI Art Generators</a></h4>
                                <span class="text-sm font-normal text-[#757575]">171 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >Misc AI Tools</a></h4>
                                <span class="text-sm font-normal text-[#757575]">793 Tools</span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-normal gap-4 rounded-2xl bg-white p-4 shadow-lg transition-shadow duration-500 hover:shadow-2xl">
                            <div class="rounded-lg bg-[red] bg-opacity-10 p-4">
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 1.66848 0.131696 1.35054 0.366117 1.11612C0.600537 0.881696 0.918479 0.75 1.25 0.75H28.75C29.0815 0.75 29.3995 0.881696 29.6339 1.11612C29.8683 1.35054 30 1.66848 30 2C30 2.33152 29.8683 2.64946 29.6339 2.88388C29.3995 3.1183 29.0815 3.25 28.75 3.25H1.25C0.918479 3.25 0.600537 3.1183 0.366117 2.88388C0.131696 2.64946 0 2.33152 0 2ZM1.25 9.5H21.25C21.5815 9.5 21.8995 9.3683 22.1339 9.13388C22.3683 8.89946 22.5 8.58152 22.5 8.25C22.5 7.91848 22.3683 7.60054 22.1339 7.36612C21.8995 7.1317 21.5815 7 21.25 7H1.25C0.918479 7 0.600537 7.1317 0.366117 7.36612C0.131696 7.60054 0 7.91848 0 8.25C0 8.58152 0.131696 8.89946 0.366117 9.13388C0.600537 9.3683 0.918479 9.5 1.25 9.5ZM28.75 13.25H1.25C0.918479 13.25 0.600537 13.3817 0.366117 13.6161C0.131696 13.8505 0 14.1685 0 14.5C0 14.8315 0.131696 15.1495 0.366117 15.3839C0.600537 15.6183 0.918479 15.75 1.25 15.75H28.75C29.0815 15.75 29.3995 15.6183 29.6339 15.3839C29.8683 15.1495 30 14.8315 30 14.5C30 14.1685 29.8683 13.8505 29.6339 13.6161C29.3995 13.3817 29.0815 13.25 28.75 13.25ZM21.25 19.5H1.25C0.918479 19.5 0.600537 19.6317 0.366117 19.8661C0.131696 20.1005 0 20.4185 0 20.75C0 21.0815 0.131696 21.3995 0.366117 21.6339C0.600537 21.8683 0.918479 22 1.25 22H21.25C21.5815 22 21.8995 21.8683 22.1339 21.6339C22.3683 21.3995 22.5 21.0815 22.5 20.75C22.5 20.4185 22.3683 20.1005 22.1339 19.8661C21.8995 19.6317 21.5815 19.5 21.25 19.5Z" fill="red"></path>
                                </svg>
                            </div>
                            <div>
                                <h4 class="text-xl font-bold text-slate-800"><a >AI Audio Generators</a></h4>
                                <span class="text-sm font-normal text-[#757575]">287 Tools</span>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="mx-4">
                    <div class="rich-text-block mx-auto w-full max-w-7xl">
                        <div class="prose:text-slate-100 prose-xl prose-slate prose-invert prose-headings:scroll-m-24 prose-headings:font-semibold prose-a:underline  prose-a:decoration-inherit prose-ol:list-decimal prose-ul:list-disc">
                            <p class="my-2">Futurepedia is a leading AI resource platform, dedicated to empowering professionals across various industries to leverage AI technologies for innovation and growth. In our rapidly evolving technological landscape, <a class="anchor" >AI tools</a> like <a class="anchor" >ChatGPT</a>, <a class="anchor">Runway ML</a>, <a class="anchor" >Gamma AI</a>, <a class="anchor" >Facecheck ID</a>, <a class="anchor" 
                            >Magic School AI</a>, <a class="anchor" >Looka</a>, <a class="anchor" >Uberduck AI</a> &amp; <a class="anchor" >Playground AI</a> are essential for advancement in nearly all areas of business. Our platform offers comprehensive directories, easy-to-follow guides, a weekly newsletter, and an informative YouTube channel, simplifying AI integration into professional practices. Committed to making AI understandable and practical, we provide resources tailored to diverse professional needs, fostering a community of more than 200,000 professionals sharing knowledge and experiences. Join us in shaping a future where AI is integral to work and innovation.</p>
                            <p class="my-2"></p>
                            <p class="my-2"></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
