import React from 'react'

export default function Footer() {
    return (
        <div>
            <footer class="bg-ice-950" aria-labelledby="footer-heading"
                style={{
                    marginTop: "4rem"
                }}
            >
                <div class="mx-auto max-w-7xl px-6 pb-8 pt-4 sm:px-0 lg:px-8 lg:pt-12">
                    <div class="w-full xl:grid xl:grid-cols-[300px_1fr_200px] xl:gap-16">
                        <div class="mb-10 mt-4 xl:mb-0 xl:mt-0">
                            <img src='' />
                            <p class="mt-4 text-white">Advertiser Disclosure: Futurepedia.io is committed to rigorous editorial standards to provide our users with accurate and helpful content. To keep our site free, we may receive compensation when you click some links on our site.</p>
                        </div>
                        <div class="grid grid-cols-2 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                            <div>
                                <p class="text-sm font-semibold leading-6 text-white">Categories</p>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Productivity Tools</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Productivity Tools</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                </ul>
                            </div>
                            <div>
                                <p class="text-sm font-semibold leading-6 text-white">Resources</p>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Productivity Tools</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Productivity Tools</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Image Generators</a></li>
                                </ul>
                            </div>
                            <div>
                                <p class="text-sm font-semibold leading-6 text-white">Company</p>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Contact Us</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Advertise</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Submit a Tool</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Request a Feature</a></li>
                                    <li><a class="text-sm leading-6 text-gray-300 hover:text-white">Update a Tool</a></li>

                                    <li><a class="text-left text-sm leading-6 text-gray-300 hover:text-white" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:ru:" data-state="closed">Create an account</a></li>
                                    <li><button class="text-sm leading-6 text-gray-300 hover:text-white">Login</button></li>
                                </ul>
                            </div>
                        </div>
                        <div class="max-w-auto mt-10 sm:max-w-[200px] xl:mt-0">
                            <div class="flex flex-col items-center justify-center">
                                <button class="inline-flex items-center justify-center text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-ice-500 text-primary-foreground hover:bg-ice-600 cursor-pointer rounded-full h-10 px-4 py-2 w-full whitespace-nowrap" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r0:" data-state="closed">Sign up for free</button>
                                <div class="mt-4 flex items-center justify-center gap-4">
                                    <a target="_blank" class="text-gray-500 hover:text-gray-400" rel="noreferrer, noopener, nofollow">
                                        <span class="sr-only">Linkedin</span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="h-6 w-6" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                        </svg>
                                    </a>
                                    <a target="_blank" class="text-gray-500 hover:text-gray-400" rel="noreferrer, noopener, nofollow">
                                        <span class="sr-only">Twitter</span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="h-6 w-6" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
                                        </svg>
                                    </a>
                                    <a target="_blank" class="text-gray-500 hover:text-gray-400" rel="noreferrer, noopener, nofollow">
                                        <span class="sr-only">YouTube</span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" class="h-6 w-6" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                                        </svg>
                                    </a>
                                    <a target="_blank" class="text-gray-500 hover:text-gray-400" rel="noreferrer, noopener, nofollow">
                                        <span class="sr-only">TikTok</span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="h-6 w-6" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path>
                                        </svg>
                                    </a>
                                    <a target="_blank" class="text-gray-500 hover:text-gray-400" rel="noreferrer, noopener, nofollow">
                                        <span class="sr-only">Instagram</span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="h-6 w-6" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                        </svg>
                                    </a>
                                    <a target="_blank" class="text-gray-500 hover:text-gray-400" rel="noreferrer, noopener, nofollow">
                                        <span class="sr-only">Facebook</span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="h-6 w-6" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
                        <div class="flex gap-4">
                            <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">

                            </p>
                            <a class="text-xs leading-5 text-ice-500 md:order-1 md:mt-0" >Privacy Policy</a><a class="text-xs leading-5 text-ice-500 md:order-1 md:mt-0" >Terms of Service</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
