import React from 'react'

export default function Three() {
    return (
        <div>

            <div class="my-10 flex items-center justify-center bg-[#030C3A] md:my-24 lg:my-[160px]">
                <div class="flex w-full flex-col items-center justify-center gap-5 px-4 py-20 xs:px-[25.5px] xl:w-auto xl:px-[260px]">
                    <div class="flex items-center justify-center gap-0 md:gap-2 lg:gap-5">
                        <div>
                            <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-trendDownText md:h-12 md:w-12 lg:h-[60px] lg:w-[60px]">
                                <path d="M33.6047 4.68358C33.3735 4.49141 33.0996 4.35763 32.8059 4.29351C32.5122 4.2294 32.2075 4.23683 31.9173 4.31518C31.6271 4.39354 31.3599 4.54051 31.1384 4.74372C30.9169 4.94693 30.7475 5.20041 30.6445 5.4828L25.4883 19.6414L19.8258 14.1547C19.6356 13.9702 19.4085 13.8282 19.1595 13.7379C18.9104 13.6476 18.6451 13.6111 18.3809 13.6308C18.1167 13.6505 17.8597 13.726 17.6268 13.8522C17.3939 13.9785 17.1904 14.1527 17.0297 14.3633C11.9531 21.0148 9.375 27.7062 9.375 34.25C9.375 39.7201 11.548 44.9661 15.4159 48.8341C19.2839 52.702 24.5299 54.875 30 54.875C35.4701 54.875 40.7161 52.702 44.5841 48.8341C48.452 44.9661 50.625 39.7201 50.625 34.25C50.625 20.3164 38.7211 8.93749 33.6047 4.68358ZM43.0992 36.439C42.613 39.1548 41.3065 41.6563 39.3554 43.6069C37.4044 45.5576 34.9025 46.8636 32.1867 47.3492C32.0838 47.3669 31.9795 47.3755 31.875 47.375C31.4047 47.3749 30.9516 47.198 30.6055 46.8794C30.2595 46.5609 30.0459 46.1239 30.0069 45.6552C29.968 45.1865 30.1066 44.7203 30.3953 44.349C30.6841 43.9777 31.1018 43.7285 31.5656 43.6508C35.4492 42.9969 38.7445 39.7015 39.4031 35.8109C39.4864 35.3205 39.7611 34.8832 40.1668 34.5953C40.5725 34.3074 41.076 34.1925 41.5664 34.2758C42.0569 34.3591 42.4941 34.6338 42.782 35.0395C43.0699 35.4452 43.1849 35.9486 43.1016 36.439H43.0992Z" fill="currentColor"></path>
                            </svg>
                        </div>
                        <h2 class="text-[32px]/[38.4px] font-semibold text-white max-[400px]:w-min xs:w-auto md:text-5xl lg:text-[64px]/[76.8px]">Trending Categories</h2>
                    </div>
                    <p class="px-[14px] text-base/[24px] text-gray-300">Explore our editorial favorites and popular AI tools in these trending categories</p>
                    <ul class="mt-[40px] flex max-w-[701px] flex-wrap justify-center gap-[10px]">
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline" >Marketing</a></li>
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline">Productivity</a></li>
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline" >Design</a></li>
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline" >Video</a></li>
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline" >Research</a></li>
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline" >Text-To-Image</a></li>
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline" >Design Generators</a></li>
                        <li><a class="shadow-[8px_10px_56px_0_rgba(0,0,0,0.05] focus:ring-ace-500 inline-flex items-center rounded-[46px] border border-grayLight bg-white px-5 py-[9px] text-base/[20.16px] font-normal text-textGray transition-colors hover:text-ice-500 focus:outline-none focus:ring-2 focus:ring-offset-2 active:shadow-[8px_10px_25px_0_rgba(85,112,255,0.15)] hover:no-underline" >Email Assistant</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
